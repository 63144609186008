import { DomainName, domains } from "../constants/domains";

export const isValidDomain = (d: string) => {
  const idx = domains.findIndex(elem => elem === d);
  return idx >= 0;
};

const useDomain = (): DomainName | undefined => {
  if (typeof window === "undefined") return;
  const fragments = window.location.pathname.split("/").filter(elem => !!elem);

  if (!fragments.length) {
    return;
  }

  const frag = fragments[0].trim();
  if (isValidDomain(frag)) {
    return frag as DomainName;
  }
  return;
};
export default useDomain;
