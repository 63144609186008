import { create } from "zustand";
import { Spokesperson } from "@/types/social";
import { spokespersons } from "@/data/social-media/data";
import {
  EditableDataExportObject,
  EditableDataObject,
  toEditable,
} from "@/lib/editableData";

export type LinkedinPost = EditableDataObject<string>;
export type LinkedinHashtags = EditableDataObject<string[]>;

export const createLinkedinData = (post: string, hashtags: string[]) => {
  return {
    post: new EditableDataObject(post),
    hashtags: new EditableDataObject(hashtags),
  };
};

export interface LinkedinMessagesState {
  post: LinkedinPost;
  hashtags: LinkedinHashtags;
}

export interface SocialStateFields {
  subject: string;
  person: Spokesperson;
  additionalInfo: string;
  tonality: number;
  linkedin: {
    hashtags: EditableDataObject<string[]>;
    post: EditableDataObject<string>;
  };
  brief: EditableDataObject<string>;
}

export interface SocialStateFieldsExport {
  subject: string;
  person: Spokesperson;
  additionalInfo: string;
  tonality: number;
  linkedin: {
    hashtags: EditableDataExportObject<string[]>;
    post: EditableDataExportObject<string>;
  };
  brief: EditableDataExportObject<string>;
}

const createEmptyLinkedinState = () => ({
  post: new EditableDataObject(""),
  hashtags: new EditableDataObject([]),
});

export interface SocialStateActions {
  setSubject: (m: string) => void;
  setPerson: (t: Spokesperson) => void;
  setTonality: (t: number) => void;
  setAdditionalInfo: (v: string) => void;
  setBrief: (m: EditableDataObject<string>) => void;
  setLinkedin: (data: LinkedinMessagesState) => void;
  setLinkedinPost: (d: LinkedinMessagesState["post"]) => void;
  setLinkedinHashtags: (d: LinkedinMessagesState["hashtags"]) => void;
  importState: (state: SocialStateFieldsExport) => void;
}

export type SocialState = SocialStateFields & SocialStateActions;

const useSocialStore = create<SocialState>((set, get) => ({
  subject: "",
  setSubject: (subject: string) => set({ subject }),

  person: spokespersons[0],
  setPerson: p => {
    return set({
      person: p,
      linkedin: createEmptyLinkedinState(),
    });
  },

  brief: new EditableDataObject(""),
  setBrief: brief => set({ brief, linkedin: createEmptyLinkedinState() }),

  tonality: 5,
  setTonality: tonality => set({ tonality }),

  additionalInfo: "",
  setAdditionalInfo: i => set({ additionalInfo: i }),

  linkedin: createEmptyLinkedinState(),
  setLinkedin: data => {
    return set({ linkedin: data });
  },

  setLinkedinPost: d => set({ linkedin: { ...get().linkedin, post: d } }),
  setLinkedinHashtags: d =>
    set({ linkedin: { ...get().linkedin, hashtags: d } }),

  importState: state => {
    const newState: SocialStateFields = {
      ...state,
      brief: new EditableDataObject("").import(state.brief ?? toEditable("")),
      linkedin: {
        post: new EditableDataObject<string>("").import(
          state.linkedin.post ?? toEditable(""),
        ),
        hashtags: new EditableDataObject([] as string[]).import(
          state.linkedin.hashtags ?? toEditable([]),
        ),
      },
    };
    return set({ ...newState });
  },
}));

export const useSocialStateFields: () => SocialStateFieldsExport = () =>
  useSocialStore(state => {
    const exportObj: SocialStateFieldsExport = {
      ...state,
      linkedin: {
        hashtags: state.linkedin.hashtags.export(),
        post: state.linkedin.post.export(),
      },
      brief: state.brief.export(),
    };
    return exportObj;
  });

export default useSocialStore;
