import { LoremIpsum } from "lorem-ipsum";

export function isOneOf<T>(val: T, list: Array<T>) {
  return list.includes(val);
}

export function isNoneOf<T>(val: T, list: Array<T>) {
  return !list.includes(val);
}

export const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});

export function updateOrAppend<T>(
  arr: T[],
  element: T,
  predicate: (elem: T) => boolean,
) {
  const newArr = [...arr];
  const elemIdx = newArr.findIndex(predicate);
  if (elemIdx >= 0) {
    newArr[elemIdx] = element;
    return newArr;
  }
  return [...newArr, element];
}

/* returns undefined if controller is false, undefined, null or 0 */
export function uif<T>(
  elem: T,
  controller: boolean | number | null | undefined,
): T | undefined {
  return controller ? elem : undefined;
}

export function arrayContains<T>(arr: T[], elem: T): boolean {
  const idx = arr.findIndex(arrElem => arrElem === elem);
  return idx !== -1;
}

export const emailToInitials = (mail: string) => {
  const frags = mail
    .split("@")[0]
    .split(".")
    .map(elem => elem.charAt(0))
    .join("");
  return frags;
};

export const clearParagraphs = (inStr: string) => {
  return inStr
    .split("\n")
    .map(elem => elem.trim())
    .filter(elem => elem.length)
    .join("\n");
};
