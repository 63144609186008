import { DomainName } from "@/lib/constants/domains";
import {
  GeneratorStateFields,
  useGeneratorStateFields,
} from "./generatorStore";
import { SocialStateFieldsExport, useSocialStateFields } from "./socialStore";

const useStoreFields = (): Record<
  DomainName,
  GeneratorStateFields | SocialStateFieldsExport
> => {
  const generator = useGeneratorStateFields();
  const social = useSocialStateFields();

  return { social, generator };
};

export default useStoreFields;
