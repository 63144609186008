import { create } from "zustand";
import { NullableData } from "@/types";
import { PromptInfo } from "@/data/types";
import { updateOrAppend } from "@/lib/helpers";
import { DEFAULT_MODEL } from "@/lib/constants/openai";
import { Model } from "@/lib/constants/models";

export interface PromptData {
  title: string;
  system: PromptInfo;
  user: NullableData<PromptInfo>;
  edit: PromptInfo;
}

export interface PromptStateFields {
  prompts: PromptData[];
  model: Model;
}

export interface PromptStateActions {
  setPrompts: (d: PromptData[]) => void;
  getPrompt: (t: string) => PromptData | undefined;
  updatePrompt: (title: string, v: PromptData) => void;
  updateUserPromptParam: (
    title: string,
    key: keyof PromptInfo,
    v: string,
  ) => void;
  resetUserToSystem: (t: string) => void;
  setModel: (m: Model) => void;
}

export type PromptState = PromptStateFields & PromptStateActions;

const usePromptStore = create<PromptState>((set, get) => ({
  prompts: [],
  setPrompts: d => set({ prompts: d }),
  updatePrompt: (title, v) => {
    const currentPromps = [...get().prompts];

    const newPrompts = updateOrAppend(
      currentPromps,
      v,
      elem => elem.title === title,
    );

    return set({ prompts: newPrompts });
  },
  updateUserPromptParam: (title, key, v) => {
    const { getPrompt, updatePrompt } = get();

    const p = getPrompt(title);
    if (!p) return;
    const newPrompt = { ...p };
    newPrompt.edit[key] = v;
    updatePrompt(title, newPrompt);
  },
  getPrompt: title => {
    const currentPromps = get().prompts;
    return currentPromps.find(elem => elem.title === title);
  },
  resetUserToSystem: title => {
    const { updateUserPromptParam, getPrompt } = get();
    const prompt = getPrompt(title)?.system;
    if (!prompt) return;
    updateUserPromptParam(title, "introTemplate", prompt["introTemplate"]);
    updateUserPromptParam(title, "typeAnnotations", prompt["typeAnnotations"]);
  },
  model: DEFAULT_MODEL,
  setModel: m => set({ model: m }),
}));

export default usePromptStore;
