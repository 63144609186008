import { Box, HStack, Text } from "@chakra-ui/react";
import Link from "next/link";
import { Fragment, FunctionComponent } from "react";
import { useLocation } from "react-use";
import { LinkDef } from "./Layout";

interface ProgressLinksProps {
  links: LinkDef[];
}

const ProgressLinks: FunctionComponent<ProgressLinksProps> = ({ links }) => {
  const { pathname } = useLocation();

  const currentIdx = links.findIndex(elem => elem.href === pathname);

  return (
    <HStack spacing={0} h="100%">
      {links.map((l, idx) => {
        const isFuture = idx > currentIdx;
        return (
          <Fragment key={l.href}>
            <Link
              href={l.href}
              key={l.name}
              style={{
                margin: 0,
                position: "relative",
                transform: "translateY(-0.5rem)",
              }}>
              <Box
                rounded="full"
                w={1}
                h={1}
                color={isFuture ? "white" : "brand.400"}
                outlineColor={isFuture ? "white" : "brand.400"}
                outline="1px solid"
                textAlign="center"
              />
              <Text
                position="absolute"
                textAlign="center"
                left="50%"
                py={1}
                transform="translate(-50%,0)"
                color={isFuture ? "white" : "brand.400"}
                _hover={{ fontWeight: "bold" }}>
                {l.name}
              </Text>
            </Link>
            {idx < links.length - 1 && (
              <Box
                h={0}
                w={16}
                borderTop="1px solid"
                borderColor={idx > currentIdx - 1 ? "white" : "brandFull"}
                zIndex={-1}
                transform="translateY(-0.5rem)"
              />
            )}
          </Fragment>
        );
      })}
    </HStack>
  );
};

export default ProgressLinks;
