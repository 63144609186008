import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";

import { FC } from "react";

import logoImage from "../../public/star.svg";

import { useUser } from "@supabase/auth-helpers-react";
import { LinkDef } from "./Layout";
import SaveButton from "./SaveButton";
import HistoryModal from "./HistoryModal";
import Link from "next/link";
import ProgressLinks from "./ProgressLinks";
import { emailToInitials } from "@/lib/helpers";
import ExportedImage from "next-image-export-optimizer";

interface NavbarProps {
  links?: LinkDef[];
  showSave: boolean;
}

interface NavbarProps {
  children?: React.ReactNode;
}
const Navbar: FC<NavbarProps> = ({ links, children, showSave }) => {
  const {
    isOpen: isHistoryOpen,
    onOpen: onHistoryOpen,
    onClose: onHistoryClose,
  } = useDisclosure();

  const user = useUser();

  const isLoggedIn = !!user;

  return (
    <>
      <HStack
        gridArea="header"
        w="full"
        p={2}
        textColor="white"
        spacing={8}
        align="center"
        zIndex="banner"
        justify="space-between">
        <HStack href="/" style={{ marginLeft: 8 }} as={Link}>
          <ExportedImage
            src={logoImage}
            alt="logo"
            width={36}
            height={36}
            style={{ marginRight: 0 }}
          />

          <HStack alignItems="end">
            <span
              style={{
                fontFamily: "corpoA",
                fontSize: "20px",
                lineHeight: 1,
              }}>
              Synapse
            </span>
            <span
              style={{
                fontFamily: "corpoS",
                fontSize: "14px",
                color: "var(--chakra-colors-brandFull)",
                lineHeight: 1,
              }}>
              v2 [stable]
            </span>
          </HStack>
        </HStack>

        {isLoggedIn && links && <ProgressLinks links={links} />}

        <HStack spacing={2}>
          {children}
          {isLoggedIn && showSave && (
            <>
              <SaveButton />
              <Button
                onClick={onHistoryOpen}
                variant="tight"
                colorScheme="brand">
                Load
              </Button>
            </>
          )}

          <Menu>
            <MenuButton
              as={Button}
              bg="whiteAlpha.600"
              rounded="full"
              w={12}
              h={12}
              textAlign="center"
              fontSize="md"
              fontWeight="bold"
              padding={0}>
              {user && user.email
                ? emailToInitials(user.email).toUpperCase()
                : "/"}
            </MenuButton>
            <MenuList>
              <MenuGroup title="Navigation">
                <Link href="/">
                  <MenuItem>Home</MenuItem>
                </Link>
                <Link href="/about">
                  <MenuItem>About</MenuItem>
                </Link>
                <Link
                  href={`mailto:hans@intuity.de?subject=Synapse%20|%20Bugreport%20${process.env.NODE_ENV}`}>
                  <MenuItem>Report problem</MenuItem>
                </Link>
              </MenuGroup>
              <MenuDivider />

              <MenuGroup title="Versions">
                <Link
                  href="https://synapse-v1.ity.cloud/"
                  target="_blank"
                  rel="noreferrer noopener">
                  <MenuItem>V1 (legacy)</MenuItem>
                </Link>
                <Link href="https://synapse.ity.cloud/">
                  <MenuItem>V2 (current stable)</MenuItem>
                </Link>
                <Link href="https://synapse-dev.ity.cloud/">
                  <MenuItem>V3 (development preview)</MenuItem>
                </Link>
              </MenuGroup>
              <MenuDivider />

              <MenuGroup title="Profile">
                {user && (
                  <Link href="/auth">
                    <MenuItem cursor="default">{user.email}</MenuItem>
                  </Link>
                )}
                {isLoggedIn ? (
                  <MenuItem as="form" action="/api/auth/signout" method="post">
                    <button className="button block" type="submit">
                      Log out
                    </button>
                  </MenuItem>
                ) : (
                  <MenuItem>
                    <Link href="/auth/signin">Log in</Link>
                  </MenuItem>
                )}
              </MenuGroup>
            </MenuList>
          </Menu>
        </HStack>
      </HStack>

      <HistoryModal
        isHistoryOpen={isHistoryOpen}
        onHistoryClose={onHistoryClose}
      />
    </>
  );
};

export default Navbar;
