import ExportedImage from "next-image-export-optimizer";

import {
  Box,
  Button,
  Grid,
  HStack,
  Icon,
  StackProps,
  VStack,
} from "@chakra-ui/react";
import Head from "next/head";
import { FC } from "react";

import { useRouter } from "next/router";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

import starPattern from "../../../public/starpattern.svg";
import Navbar from "../Navbar";
import ProModeDrawer from "../PromodeDrawer";
import { usePromode } from "@/lib/context/ProModeContext";
import { useEffectOnce } from "react-use";
import { ChevronUp } from "lucide-react";
import { useIsLoading } from "@/lib/context/LoadingContext";
import { uif } from "@/lib/helpers";
import useDomain from "@/lib/hooks/useDomain";

export type LinkDef = {
  href: string;
  name: string;
};

interface LayoutProps {
  children: React.ReactNode;
  nav?: React.ReactNode;
  footerChildren?: React.ReactNode;
  footerButtons?: React.ReactNode;
  header?: React.ReactNode;
  pageBefore?: LinkDef;
  pageNext?: LinkDef;
  nextDisabled?: boolean;
  footerStyles?: StackProps;
  proModeOptions?: {
    title: string;
    key: string;
    onSubmit: (v?: boolean) => void;
  };
  navLinks?: LinkDef[];
}

const Layout: FC<LayoutProps> = ({
  children,
  footerChildren,
  pageBefore,
  pageNext,
  nextDisabled,
  footerStyles,
  footerButtons,
  proModeOptions,
  navLinks,
}) => {
  const router = useRouter();

  const { promodeEnabled, setPromodeEnabled } = usePromode();
  const { isLoading, clearQueue } = useIsLoading();

  /* if we are in a valid domain (generator, social) we can show
  save/load stuff */

  const domain = useDomain();

  useEffectOnce(() => {
    if (!proModeOptions) {
      setPromodeEnabled(false);
    }
    /* clear the queue to get rid of ongoing requests */
    clearQueue();
  });

  return (
    <>
      <Head>
        <title>synapse</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Grid
        w="100vw"
        h="100vh"
        overflow="hidden"
        templateAreas={`
        "header drawer"
        "content drawer"
        "footer drawer"
        `}
        transition="all 200ms ease-in-out"
        textColor="white"
        templateRows="auto 1fr auto"
        templateColumns={`1fr ${promodeEnabled ? "var(--chakra-space-96)" : 0}`}
        bgPosition="center"
        bgSize="cover"
        position="fixed"
        bg="black">
        <Navbar links={navLinks} showSave={!!domain} />
        <Box
          gridArea="content"
          w="full"
          h="full"
          position="relative"
          overflow="hidden"
          id="content-container">
          {children}

          {proModeOptions && (
            <Button
              bg="brandFull"
              position="absolute"
              top="50%"
              paddingX={8}
              right={"-66px"}
              iconSpacing={2}
              fontSize="md"
              roundedBottom={0}
              roundedTop={20}
              transform="rotate(-90deg)"
              onClick={() => setPromodeEnabled(!promodeEnabled)}
              rightIcon={
                <Icon
                  boxSize={6}
                  as={ChevronUp}
                  transition="all 500ms"
                  transform={uif("rotate(180deg)", promodeEnabled)}
                />
              }>
              PRO MODE
            </Button>
          )}
        </Box>
        <VStack gridArea="footer" py={4} {...footerStyles}>
          <HStack color="white" spacing={4}>
            {pageBefore && (
              <Button
                aria-label="back"
                size="sm"
                rounded="full"
                onClick={() => router.push(pageBefore.href)}
                leftIcon={<ArrowBackIcon />}
                variant="outline">
                back
              </Button>
            )}
            {pageNext && (
              <Button
                rounded="full"
                opacity={nextDisabled ? 0.5 : 1}
                ml="auto"
                onClick={
                  nextDisabled ? undefined : () => router.push(pageNext.href)
                }
                size="sm"
                disabled={nextDisabled}
                cursor={nextDisabled ? "not-allowed" : undefined}
                rightIcon={<ArrowForwardIcon />}>
                {pageNext.name}
              </Button>
            )}
            {footerButtons}
          </HStack>
          {footerChildren}
        </VStack>
        <ExportedImage
          alt=""
          src={starPattern}
          fill
          style={{
            objectFit: "cover",
            zIndex: -10,
          }}
          className={uif("pulse", isLoading)}
        />
        <Box
          position="absolute"
          bgGradient="radial(rgba(0,0,0,0),rgba(0,0,0,0), rgba(0,0,0))"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="-10"
        />
        {proModeOptions && (
          <ProModeDrawer
            isOpen={promodeEnabled}
            onClose={() => setPromodeEnabled(false)}
            title={proModeOptions.title}
            promptKey={proModeOptions.key}
            onSubmit={proModeOptions.onSubmit}
          />
        )}
      </Grid>
    </>
  );
};

export default Layout;
