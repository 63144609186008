import useMainStore from "@/stores/generatorStore";
import useSocialStore from "@/stores/socialStore";
import {
  SupabaseClient,
  createClientComponentClient,
  createPagesBrowserClient,
} from "@supabase/auth-helpers-nextjs";
import { logIfDev } from "./console";

const logError = (v: unknown) => {
  const prod = process.env.NODE_ENV === "production";

  let msg = "";

  switch (typeof v) {
    case "undefined": {
      return;
    }
    case "string": {
      msg = v;
      break;
    }
    case "object": {
      msg = JSON.stringify(v);
      break;
    }
    default: {
      msg = `Unknown format: ${v}`;
      break;
    }
  }

  if (prod) {
    const social = useSocialStore.getState();
    const generator = useMainStore.getState();

    let url = "backend";

    let supabase: SupabaseClient;
    if (typeof window !== undefined) {
      url = window.location.pathname.toString();
      supabase = createClientComponentClient();
    } else {
      supabase = createPagesBrowserClient();
    }

    supabase
      .from("error-log")
      .insert({
        error_message: msg,
        note: "AUTOMATIC",
        url,
        store_data: { social, generator },
      })
      .then(() => {
        // eslint-disable-next-line no-console
        logIfDev("logged error");
      });
  }
};

export default logError;
