import useDomain from "@/lib/hooks/useDomain";
import logError from "@/lib/logging";
import { successToast } from "@/lib/notifications/toastMessages";
import useStoreFields from "@/stores";
import { Button } from "@chakra-ui/react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import delay from "delay";
import { FC, useState } from "react";

const SaveButton: FC = () => {
  const stores = useStoreFields();
  const supabaseClient = useSupabaseClient();
  const [isLoading, setIsLoading] = useState(false);
  const domain = useDomain();

  const handleSave = async () => {
    let state;

    switch (domain) {
      case "social": {
        state = stores.social;
        break;
      }
      case "generator": {
        state = stores.generator;
        break;
      }
      default: {
        logError("invalid store route / domain");
        break;
      }
    }

    setIsLoading(true);
    const { error } = await supabaseClient
      .from("projects")
      .insert({ state, domain });

    await delay(1000);

    if (error) logError(error);
    setIsLoading(false);
    successToast("Project saved!");
  };

  return (
    <Button
      onClick={handleSave}
      isLoading={isLoading}
      loadingText="Saving"
      variant="tight">
      Save
    </Button>
  );
};

export default SaveButton;
