import { create } from "zustand";
import { Result as DeviceResult } from "@/types/device";
import { createEmptyDeviceState } from "../res/placeholderData";
import {
  EditableDataExportObject,
  EditableDataObject,
  toEditable,
} from "@/lib/editableData";

export type DeviceType = keyof DeviceMessagesState;

const invalidatedDevices: Partial<GeneratorState> = {
  deviceMessages: createEmptyDeviceState(),
};

const invalidatedBrief: Partial<GeneratorState> = {
  brief: new EditableDataObject(""),
  ...invalidatedDevices,
};

export interface GeneratorStateFields {
  productDescription: string;
  tonality: number;
  useBulletPoints: boolean;
  additionalInfo: string;
  brief: EditableDataObject<string>;
  deviceMessages: {
    app: EditableDataObject<DeviceResult>;
    mail: EditableDataObject<DeviceResult>;
  };
}

export interface GeneratorStateFieldsExport {
  productDescription: string;
  tonality: number;
  useBulletPoints: boolean;
  additionalInfo: string;
  brief: EditableDataExportObject<string>;
  deviceMessages: {
    app: EditableDataExportObject<DeviceResult>;
    mail: EditableDataExportObject<DeviceResult>;
  };
}

export type DeviceMessagesState = GeneratorStateFields["deviceMessages"];

export interface GeneratorStateActions {
  setProductDescription: (m: string) => void;
  setTonality: (t: number) => void;
  setUseBulletPoints: (v: boolean) => void;
  setAdditionalInfo: (v: string) => void;

  setBrief: (m: EditableDataObject<string>) => void;
  setDeviceMessages: (m: DeviceMessagesState) => void;
  updateDeviceMessages: (m: Partial<DeviceMessagesState>) => void;

  setMessageForDevice: (
    data: EditableDataObject<DeviceResult>,
    device: DeviceType,
  ) => void;
  importState: (state: GeneratorStateFieldsExport) => void;
}

export type GeneratorState = GeneratorStateFields & GeneratorStateActions;

const useGeneratorStore = create<GeneratorState>((set, get) => ({
  productDescription: "",
  setProductDescription: m =>
    set({
      productDescription: m,
      ...invalidatedBrief,
    }),
  tonality: 5,
  setTonality: n =>
    set({
      tonality: n,
      ...invalidatedDevices,
    }),
  useBulletPoints: false,
  setUseBulletPoints: (v: boolean) =>
    set({
      useBulletPoints: v,
      ...invalidatedBrief,
    }),
  additionalInfo: "",
  setAdditionalInfo: (v: string) =>
    set({ additionalInfo: v, ...invalidatedBrief }),

  brief: new EditableDataObject(""),
  setBrief: m => set({ brief: m, deviceMessages: createEmptyDeviceState() }),

  deviceMessages: createEmptyDeviceState(),
  setDeviceMessages: d => set({ deviceMessages: d }),
  updateDeviceMessages: d => {
    return set({ deviceMessages: { ...get().deviceMessages, ...d } });
  },

  setMessageForDevice: (data, device) => {
    const newDeviceMessages = { ...get().deviceMessages, [device]: data };
    return set({ deviceMessages: newDeviceMessages });
  },

  importState: state => {
    const newState: GeneratorStateFields = {
      ...state,
      brief: new EditableDataObject("").import(state.brief ?? toEditable("")),
      deviceMessages: {
        app: new EditableDataObject<DeviceResult>({
          title: "",
          message: "",
        }).import(
          state.deviceMessages?.app ?? toEditable({ message: "", title: "" }),
        ),
        mail: new EditableDataObject<DeviceResult>({
          title: "",
          message: "",
        }).import(
          state.deviceMessages?.mail ?? toEditable({ message: "", title: "" }),
        ),
      },
    };
    return set({ ...newState });
  },
}));

export const useGeneratorStateFields: () => GeneratorStateFields = () =>
  useGeneratorStore(state => ({
    productDescription: state.productDescription,
    tonality: state.tonality,
    useBulletPoints: state.useBulletPoints,
    additionalInfo: state.additionalInfo,
    brief: state.brief,
    deviceMessages: state.deviceMessages,
  }));

export default useGeneratorStore;
