import { toast } from "../export/funct";

export const copyToast = (name: string) =>
  successToast(`Copied ${name} to clipboard!`);

export const successToast = (body: string) =>
  toast({ title: "Success", description: body, status: "success" });

export const errorToast = (body: string) =>
  toast({ title: "Error", description: body, status: "error" });
