import { Box, HStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface ListProgressBarProps {
  stages: boolean[];
}

const ListProgressBar: FunctionComponent<ListProgressBarProps> = ({
  stages,
}) => {
  return (
    <HStack spacing={1} height="min-content">
      {stages &&
        stages.map((s, idx) => (
          <Box
            h={1}
            w={4}
            rounded="full"
            key={idx}
            bg={s ? "brandFull" : "gray.200"}
          />
        ))}
    </HStack>
  );
};

export default ListProgressBar;
