import { EditableDataObject } from "@/lib/editableData";
import { EditableData } from "@/types";
import { DeviceMessagesState } from "../generatorStore";

const emptyEditableStringData: EditableData<string> = {
  user: "",
  base: "",
};
export const createEmptyEditableStringData = (): EditableData<string> => ({
  ...emptyEditableStringData,
});

const emptyDeviceState: DeviceMessagesState = {
  app: new EditableDataObject({ title: "", message: "" }),
  mail: new EditableDataObject({ title: "", message: "" }),
};

export const createEmptyDeviceState = () => ({ ...emptyDeviceState });
