import {
  Center,
  Grid,
  GridItem,
  HStack,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";
import { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import useGeneratorStore, {
  GeneratorStateFieldsExport,
} from "@/stores/generatorStore";
import dateFormat from "dateformat";
import useSWR from "swr";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import ListProgressBar from "./ListProgressBar";
import useDomain from "@/lib/hooks/useDomain";
import useSocialStore, { SocialStateFieldsExport } from "@/stores/socialStore";
import { DomainName } from "@/lib/constants/domains";
import { extractEditableData } from "@/lib/editable";

export interface HistoryItem {
  created_at: number;
  id: string;
  state: GeneratorStateFieldsExport | SocialStateFieldsExport;
  user_id: string;
}

interface HistoryItemWithStages extends HistoryItem {
  stages: boolean[];
}

interface HistoryModalProps {
  isHistoryOpen: boolean;
  onHistoryClose: () => void;
}

const getDisplayName = (item: HistoryItem, domain: DomainName) => {
  switch (domain) {
    case "generator": {
      const state = item.state as GeneratorStateFieldsExport;
      return state.productDescription;
    }

    case "social": {
      const state = item.state as SocialStateFieldsExport;
      return state.subject;
    }
  }
};

const extractProgress = (
  item: HistoryItem,
  domain: DomainName | undefined,
): boolean[] | undefined => {
  switch (domain) {
    case "generator": {
      try {
        const state = item.state as GeneratorStateFieldsExport;
        const data: boolean[] = [
          state.brief ? extractEditableData(state.brief) : "",
          state.deviceMessages.app
            ? extractEditableData(state.deviceMessages.app).message
            : "",
          state.deviceMessages.mail
            ? extractEditableData(state.deviceMessages.mail).message
            : "",
        ].map(elem => elem.length > 0);

        return data;
      } catch (e) {
        return;
      }
    }

    case "social": {
      try {
        const state = item.state as SocialStateFieldsExport;
        const data: boolean[] = [
          state.brief ? extractEditableData(state.brief) : "",
          state.linkedin ? extractEditableData(state.linkedin.post) : "",
        ].map(elem => elem.length > 0);

        return data;
      } catch (e) {
        return;
      }
    }
  }
  return;
};

const HistoryModal: FC<HistoryModalProps> = ({
  isHistoryOpen,
  onHistoryClose,
}) => {
  const { importState: importCommunicationState } = useGeneratorStore();
  const { importState: importSocialState } = useSocialStore();
  const supabaseClient = useSupabaseClient();

  const domain = useDomain();

  const prettyPrintDate = (time: number) =>
    dateFormat(time, "ddd dd mmm, HH:MM");

  const { data, error, isValidating, isLoading, mutate } = useSWR<
    HistoryItem[]
  >(
    "projects",
    async () =>
      (
        await supabaseClient
          .from("projects")
          .select("*")
          .eq("domain", domain?.toLowerCase())
          .order("created_at", { ascending: false })
      ).data || [],
  );

  const items: HistoryItemWithStages[] = useMemo(
    () =>
      data
        ?.map(elem => {
          const stages = extractProgress(elem, domain);
          if (stages) {
            return { ...elem, stages };
          }
        })
        .filter((elem): elem is HistoryItemWithStages => !!elem) ?? [],
    [data, domain],
  );

  useEffect(() => {
    isHistoryOpen && mutate();
  }, [isHistoryOpen, mutate]);

  const handleLoad = useCallback(
    (state: GeneratorStateFieldsExport | SocialStateFieldsExport) => {
      switch (domain) {
        case "generator": {
          importCommunicationState(state as GeneratorStateFieldsExport);
          break;
        }
        case "social": {
          importSocialState(state as SocialStateFieldsExport);
          break;
        }
      }
      onHistoryClose();
    },
    [domain, importCommunicationState, importSocialState, onHistoryClose],
  );

  const handleDelete = async (id: string) => {
    const { error } = await supabaseClient
      .from("projects")
      .delete()
      .eq("id", id);

    /* we can modify the swr-result to get instant updates. they will then
    be updated by SWR from the database */
    if (error || !data) return;
    const itemIdx = data.findIndex(elem => elem.id === id);
    if (itemIdx >= 0) {
      const newArr = [...data];
      newArr.splice(itemIdx, 1);
      mutate(newArr);
    }
  };

  return (
    <Modal
      isOpen={isHistoryOpen}
      onClose={onHistoryClose}
      size="2xl"
      isCentered>
      <ModalOverlay backdropFilter="auto" backdropBlur="8px" />
      <ModalContent>
        <ModalHeader>Load communication</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="auto">
          <VStack h="80vh" minH="80vh">
            {isValidating || isLoading ? (
              <Center w="full" h="full" flexDirection="column">
                <Spinner />
                <Text>Retrieving data…</Text>
              </Center>
            ) : (
              <>
                {error && <Text>Error</Text>}

                {domain && data && (
                  <Grid
                    w="full"
                    templateColumns="1fr auto auto auto"
                    rowGap={4}
                    pb={4}
                    columnGap={4}>
                    {items.length ? (
                      items.map(elem => (
                        <Fragment key={elem.id}>
                          <GridItem
                            justifyContent="center"
                            alignItems="center"
                            display="flex">
                            <Text
                              flex="1"
                              cursor="pointer"
                              onClick={() => handleLoad(elem.state)}>
                              {getDisplayName(elem, domain) || "untitled"}
                            </Text>
                          </GridItem>
                          <GridItem
                            justifyContent="center"
                            alignItems="center"
                            display="flex">
                            <ListProgressBar stages={elem.stages} />
                          </GridItem>

                          <GridItem
                            justifyContent="center"
                            alignItems="center"
                            display="flex">
                            <Text>{prettyPrintDate(elem.created_at)}</Text>
                          </GridItem>
                          <GridItem
                            justifyContent="center"
                            alignItems="center"
                            display="flex">
                            <IconButton
                              icon={<DeleteIcon />}
                              variant="unstyled"
                              aria-label="delete"
                              color="brandFull"
                              onClick={e => {
                                // dont trigger parent ListItem click
                                e.stopPropagation();
                                handleDelete(elem.id);
                              }}
                            />
                          </GridItem>
                        </Fragment>
                      ))
                    ) : (
                      <List>
                        <ListItem
                          as={HStack}
                          justifyContent="space-around"
                          w="full">
                          <Text fontStyle="italic">no items yet</Text>
                        </ListItem>
                      </List>
                    )}
                  </Grid>
                )}
              </>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HistoryModal;
