export class EditableDataObject<T> {
  private user: T;
  private base: T;
  private initial: T;

  constructor(initialData: T) {
    this.user = initialData;
    this.base = initialData;
    this.initial = initialData;
  }

  reset() {
    this.base = this.initial;
    this.user = this.initial;
    return this;
  }

  revert() {
    this.user = this.base;
    return this;
  }

  setUser(data: T) {
    this.user = data;
    return this;
  }

  setBase(data: T) {
    this.base = data;
    return this;
  }

  get(): T {
    return this.user ?? this.base;
  }

  getUser(): T {
    return this.user;
  }

  getBase(): T {
    return this.base;
  }

  write(data: T) {
    this.base = data;
    this.user = data;
    return this;
  }

  import({ base, user }: { base: T; user: T }) {
    this.base = base;
    this.user = user;
    return this;
  }

  export() {
    return { base: this.base, user: this.user };
  }
}

export type EditableDataExportObject<T> = { user: T; base: T };

export function toEditable<T>(val: T) {
  return { base: val, user: val };
}
