import { Result } from "@/types/device";

import { ToastProps, createStandaloneToast } from "@chakra-ui/react";
import { toastDefaults } from "../ui";

const { toast: standaloneToast } = createStandaloneToast();

export const toast = (toastProps: ToastProps) =>
  standaloneToast({ ...toastDefaults, ...toastProps });

export const messageToCopyableString = (d: Result | null): string => {
  if (!d) return "";
  return [d.title, d.message].map(s => s.trim()).join("\n\n");
};

export const copyImageToClipboard = (blob: Blob) => {
  try {
    navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
    toast({
      title: "Copied image!",
      description: "The preview was copied to your clipboard.",
      status: "success",
    });
  } catch (e) {
    toast({
      title: "Copying failed",
      description:
        "Your browser might not support copying to the clipboard. Please use the latest version of Google Chrome or Edge",
      status: "error",
    });
  }
};
