import { clearParagraphs } from "@/lib/helpers";
import { CommunicationGuideline, Spokesperson } from "@/types/social";

import burzerImg from "./res/burzer.jpg";
import kaelleniusImg from "./res/kaellenius.jpg";
import jungoBruenggerImg from "./res/jungo-bruengger.jpg";
import kohleisenImg from "./res/kohleisen.jpg";
import oestbergImg from "./res/oestberg.jpg";
import seegerImg from "./res/seeger.jpg";
import schaeferImg from "./res/schaefer.jpg";

export const spokespersons: Spokesperson[] = [
  {
    name: "Ola Källenius",
    identifier: "ola-kaellenius",
    title: "Chairman of the Board of Management of Mercedes-Benz Group AG",
    img: kaelleniusImg,
    spec: clearParagraphs(`
    - Personal Connection: Each post contains a personal touch or connection, either to an event, a product, or a sentiment.
    - Descriptive and Expressive: Use of descriptive adjectives and vivid imagery to convey information or feelings.
    - Use of Dates and Timelines: Specific dates, periods, or timelines are mentioned to provide context or emphasize timeliness.
    - Inclusion of Details: Detailed specifications or features of products, events, or partnerships are provided.
    - Use of Hashtags: Incorporation of specific and relevant hashtags, often relating to products or events.
    - Anticipation and Forward-Looking Statements: There's an emphasis on what's coming next or future developments.
    - Inclusive Language: Phrases such as "we", "our", "us" are used to foster inclusivity and community.
    - Direct Address to the Audience: The posts often speak directly to the reader using terms like "you" or offering invitations.
    - Appreciation and Acknowledgment: The author acknowledges teams, partners, or individuals, showcasing gratitude or giving credit.
    - Clear Structuring: Each post has a clear beginning, middle, and end—introducing the topic, elaborating on it, and then concluding or summarizing.
    - Emphasis on Quality and Excellence: The language consistently promotes high standards, quality, and excellence in products or services.`),
  },
  {
    name: "Britta Seeger",
    identifier: "britta-seeger",
    title:
      "Member of the Board of Management of Mercedes-Benz Group AG. Marketing & Sales",
    img: seegerImg,
    spec: clearParagraphs(`
    - Opening sentence highlighting a key achievement or focus area.
    - Mention of the time period under discussion.
    - Detailed quantitative data supporting statements, including percentage increases and exact unit numbers.
    - Specific products or services contributing to success.
    - Personal touch by the writer emphasizing standout points or feelings of pride.
    - Sales or performance metrics across different business segments.
    - Teasers or mentions of new offerings or future events.
    - Overall sentiment about customer feedback and motivation for the future.
    - Encouragement for readers to explore further or stay engaged with future updates.
    `),
  },
  {
    name: "Markus Schäfer",
    identifier: "markus-schaefer",
    title:
      "Member of the Board of Management of Mercedes-Benz Group AG. Chief Technology Officer, Development & Procurement",
    img: schaeferImg,
    spec: clearParagraphs(`
    - Announcement Style: Posts often start with an announcement or significant update about a product, project, or event.
    - Personal Voice: The use of "I" to convey personal sentiments and thoughts.
    - Detailed Specifications: The posts break down features, numbers, and technical details about products.
    - Use of Symbols and Emojis: Usage of ▶️ to highlight or introduce key points and details.
    - Structured Lists: Benefits, reasons, or features are presented in a structured and numbered/bulleted format.
    - Direct Address to the Audience: The posts engage readers directly with phrases like "Take a closer look" or "Here’s a quick snapshot."
    - Anticipation Building: The writer builds anticipation about future updates, reveals, or more details to come.
    - Engaging Questions: Some posts begin with questions that pique the readers' curiosity or challenge assumptions.
    - Hashtags: Incorporation of topical hashtags relevant to the content at the end of the post.
`),
  },

  {
    name: "Sabine Kohleisen",
    identifier: "sabine-kohleisen",
    title:
      "Member of the Board of Management of Mercedes-Benz Group AG. Human Relations & Labour Director",
    img: kohleisenImg,
    spec: clearParagraphs(`
    - Use professional and positive language.
    - Incorporate industry-specific hashtags (e.g., #AI, #leadership).
    - Highlight company initiatives and achievements.
    - Share personal insights and experiences.
    - Encourage engagement and feedback from the audience.
    - Provide actionable advice or recommendations.
    - Focus on employee development and training.
    - Emphasize the importance of innovation and technology.
    - Showcase diverse voices and perspectives within the company.
    - Link to additional resources or further information.
`),
  },
  {
    name: "Renata Jungo Brüngger",
    identifier: "renata-jungo-bruengger",
    title:
      "Member of the Board of Management of Mercedes-Benz Group AG. Integrity, Governance & Sustainability",
    img: jungoBruenggerImg,
    spec: clearParagraphs(`
    - Incorporate a personal touch, such as mentioning a recent event or personal connection to the topic.
    - Focus on sustainability and transformation themes, especially in relation to Mercedes-Benz.
    - Emphasize technological advancements such as automated driving and artificial intelligence.
    - Include specific references to high-level discussions or talks at industry events.
    - Use a tone that balances professional insight with approachable language.
    - Highlight collaborations and interdisciplinary approaches within the company.
    - Mention key personnel or departments involved in the initiatives.
    - Incorporate relevant hashtags focusing on the main topics like #Sustainability, #AI, #Transformation.
    - Include a call-to-action or a link to more information.
    - Express gratitude or acknowledgment towards colleagues and event organizers.
`),
  },
  {
    name: "Jörg Burzer",
    identifier: "joerg-burzer",
    title:
      "Member of the Board of Management of Mercedes-Benz Group AG. Production, Quality & Supply Chain Management",
    img: burzerImg,
    spec: clearParagraphs(`
    - Use a professional and optimistic tone.
    - Highlight partnerships and technological advancements (e.g., with Microsoft).
    - Emphasize efficiency, resilience, and sustainability.
    - Include specific examples of innovation (e.g., MO360 Data Platform).
    - Mention locations and global connectivity (e.g., Berlin, Tuscaloosa).
    - Recognize team contributions and express gratitude.
    - Discuss specific sustainability strategies and achievements.
    - Incorporate relevant hashtags for emphasis and visibility.
    - Provide additional information or links for further details.
    - Maintain a focus on future goals and visions (e.g., CO2-neutrality, electrification).
`),
  },
  {
    name: "Magnus Östberg",
    identifier: "magnus-oestberg",
    title: "Chief Software Officer",
    img: oestbergImg,
    spec: clearParagraphs(`
    - Use hashtags for emphasis and to highlight key topics.
    - Start posts with attention-grabbing statements or questions.
    - Provide detailed explanations of technological features and benefits.
    - Use a conversational and enthusiastic tone.
    - Include questions to engage the audience and encourage interaction.
    - Emphasize innovation and advancements in technology.
    - Highlight customer benefits and experiences.
    - Mention partnerships and integrations with other companies or technologies.
    - Discuss data privacy and security measures.
    - Use a mix of technical and layman's terms to appeal to a broad audience.
`),
  },
];

export const getGuidelineForPerson = (
  id: string,
): CommunicationGuideline | null => {
  const s = spokespersons.find(p => p.identifier === id);
  if (!s) return null;
  return { person: s.identifier, guideline: s.spec ?? "" };
};
